import {
  Component,
  ElementRef,
  Input,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe],
  selector: 'app-base-dialog',
  template: `
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ headerText() | translate }}</h3>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          data-dismiss="modal"
          tabindex="-1"
          (click)="dismissOverride ? dismissOverride() : modal.dismiss()"
        ></button>
      </div>

      <ng-content #bodyWrapper></ng-content>
    </div>
  `,
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent {
  modal = inject(NgbActiveModal);
  bodyWrapper = viewChild<ElementRef>('bodyWrapper');
  headerText = input('FORM_DIALOG_HEADER');
  @Input() dismissOverride?: () => void;

  dismiss() {
    console.log('dismiss modal');

    this.modal.dismiss();
  }
}
